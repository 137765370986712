
import { apiService } from '@/services/api.service';
import { Options, Vue } from 'vue-class-component';
import { Ticket } from '@/common/types/Ticket.type';

@Options({
	name: 'DigitalTicketsView',
})
export default class DigitalTicketsView extends Vue {
	ticket: Ticket | null = null;

	get ticketLogo() {
		return this.ticket ? `${this.ticket.PhotoDomain}v${this.ticket.DarkLogoVersion ?? ''}${this.ticket.PhotoFolder}/darklogo` : '';
	}

	get ticketQrCode() {
		if(!this.ticket || !this.ticket.TicketUUID)
			return '';
		
		const apiBase = process.env.VUE_APP_DEV_CONFIG_URL ?? window.location.origin;
		const ticketUUID = this.ticket.TicketUUID;

		const qrCodeSize = 240;
		var payload = `w;${ticketUUID}`;
		return `${apiBase}/api/qr?s=${qrCodeSize}&p=${payload}`;
	}

	get activities() {
		return this.ticket?.Activities.map((a: any) => {
			return {
				name: a.ActivityName,
				date: {
					day: a.AppointmentTypeKey <= 2 ? a.AppointmentDate : '',
					time: a.AppointmentTypeKey == 1 ? `@ ${a.AppointmentTime}` : '',
				},
				disclaimer: a.Disclaimer ?? '',
				rates: a.Rates.reduce((p: string[], c: any) => {
					if (c.Participants > 0) p.push(`${c.RateName} x ${c.Participants}`);
					return p;
				}, []),
			};
		});
	}

	created() {
		// get ticket object & qr code
		const ticketApi = process.env.VUE_APP_DEV_CONFIG_URL ?? window.location.origin;
		const orderKey = this.$route.query.orderkey;
		if (ticketApi && orderKey)
			apiService
				.externalGet(`${ticketApi}/api/getTicket`, {
					orderKey,
				})
				.then((res) => {
					if (!res.errors) {
						this.ticket = res as Ticket;
						console.log('[Ticket]', this.ticket);
						return this.ticket.TicketUUID;
					}
				})
				.catch((e) => console.error('Could not get ticket', e));
		else console.error(`Invalid Config URL (${ticketApi}) or ticket order key (${orderKey})`);
	}
}
